import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
const fb = require('../firebaseConfig.js')
Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('getLeads')
    } else {
    store.commit('setCurrentUser', null)
  }
})


export const store = new Vuex.Store({
  state: {
  	leads: [],
  	leadInfo: {},
  	currentUser: null,
  },
  actions: {
  	newLead({ commit }, payload) {
  		console.log(payload)
  		let content = {
  			name: payload.name,
	      email: payload.email,
	      phone: payload.phone,
	      how: payload.how,
	      dob: payload.dob,
	      description: payload.description,
	      first: payload.first,
	      size: payload.size,
	      status: 'New'
  		}
  		if (payload.image) {
  			payload.image.generateBlob(
  				blob => {
            let downloadURL
            let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
            let picRef = fb.storageRef.child('tats/' + rand)
            picRef.put(blob).then((snap) => {
              picRef.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL)
                fb.leadsCollection.add(content)
                .then(
                  doc => {
                    fb.leadsCollection.doc(doc.id).update({
	                    picUrl: downloadURL,
	                    id: doc.id,
	                    created: fb.firestore.FieldValue.serverTimestamp(),
	                  })
	                }
	              )
	            })
            })
          }
  			)
  		} else {
  			fb.leadsCollection.add(payload)
	      .then(
	        doc => {
	          fb.leadsCollection.doc(doc.id).update({
	            id: doc.id,
	            created: fb.firestore.FieldValue.serverTimestamp(),
	            status: 'New'
	          })
	        }
	      )
  		}
    },
  	getLeads({ commit }) {
      fb.leadsCollection.orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let leadsArray = []
        querySnapshot.forEach(doc => {
          let leads = doc.data()
          leads.id = doc.id
          leadsArray.push(leads)
        })
        commit('setLeads', leadsArray)
      })
    },
    getLeadFromId({ commit }, payload) {
      fb.leadsCollection.where("id", "==", payload)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          commit("setLeadInfo", doc.data())
        })
      })
    },
    clearLeadState({ commit }) {
      commit('setLeadInfo', null)
    },
    async logout({ commit, dispatch }) {
      await fb.auth.signOut()
      dispatch('clearData')
      router.push('/')
    },
    clearData({ commit }) {
      commit('setCurrentUser', null)
      commit('setLeads', [])
    },
  },
  mutations: {
  	setLeads(state, val) {
      if (val) {
        state.leads = val
      } else {
        state.leads = []
      }
    },
    setLeadInfo(state, payload) {
      state.leadInfo = payload
    },
  	setCurrentUser(state, val) {
      state.currentUser = val
    },
  }
})
