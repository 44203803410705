<template>
  <div id="app">
    <!-- <Nav /> -->
    <router-view :key="$route.fullPath"></router-view>
    <Cookies />
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Cookies from '@/components/Cookies.vue'
const fb = require('./firebaseConfig.js')

export default {
  data: () => ({
  }),
  computed: {
    ...mapState(['currentUser', 'leads']),
  },
  components: {
    Cookies,
  },
  metaInfo: () => ({
    title: 'Welcome',
    titleTemplate: '%s | Cactus Plant Studio',
    htmlAttrs: {
      lang: 'en',
      amp: undefined
    },
    headAttrs: {
      test: true
    },
    bodyAttrs: {
      tabIndex: 0
    },
    meta: [
      { name: 'description', content: "Zach Garnica Tattoos" },
      {property: 'og:title', content: 'Cactus Plant Studio Tattoos'},
      {property: 'og:type', content: 'website'},
      {property: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/cactus-78078.appspot.com/o/logo.1251b62d.png?alt=media&token=32674d2c-6abd-476d-bff5-f6c39c5f503c'},
      {property: 'og:description', content: 'Single-needle, fine line, private tattoo studio. By appointment only. Monterey, ca'}
    ],
  })
};
</script>