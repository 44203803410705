import firebase from 'firebase/app';

require("firebase/auth")
require("firebase/storage")
require("firebase/firestore")
require("firebase/functions")
require("firebase/analytics")
require("firebase/performance")

// firebase init goes here
const firebaseConfig = {
  apiKey: "AIzaSyB1lkkjOATzl2TVbt0TMnxqbYHxhG10PQY",
  authDomain: "cactus-78078.firebaseapp.com",
  databaseURL: "https://cactus-78078.firebaseio.com",
  projectId: "cactus-78078",
  storageBucket: "cactus-78078.appspot.com",
  messagingSenderId: "506484840443",
  appId: "1:506484840443:web:9bdd69916eec7964a85d5e",
  measurementId: "G-8T5GGS7BW8"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics()
firebase.performance()
firebase.firestore().enablePersistence()

const db = firebase.firestore()
const firestore = firebase.firestore
const auth = firebase.auth()
const storageRef = firebase.storage().ref()
const currentUser = auth.currentUser
const functions = firebase.functions()

// firebase collections
const leadsCollection = db.collection('leads')
const usersCollection = db.collection('users')

export {
  db,
  auth,
  storageRef,
  firestore,
  currentUser,
  functions,
  usersCollection,
  leadsCollection
}