import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/index.js'
import AOS from "aos"
import "aos/dist/aos.css"
import VueMask from 'v-mask'
import InstaVue from "insta-vue"
import Croppa from 'vue-croppa';
import VueMeta from 'vue-meta'
import './assets/sass/main.scss'
const fb = require('./firebaseConfig.js')

Vue.use(VueMask);
Vue.use(InstaVue);
Vue.use(Croppa);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(require('vue-moment'));

Vue.config.productionTip = false
Vue.config.devtools = true

let app
fb.auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      created() {
        AOS.init({ 
          easing: 'ease-in-out',
          offset: 0,
          duration: 400,
        });
      },
      render: h => h(App)
    })
  }
})